<template>
  <div class="main-container">
    <section class="flex justify-center items-center second-container">
      <article class="flex flex-col px-5">
        <img class="logo" src="../assets/logo_off.png" />

        <div class="flex flex-col" style="width: 100%">
          <input class="input" :class="{'error': error}" v-model="form.pseudo" type="text" id="pseudo" placeholder="Pseudo">
          <p class="ml-4 mt-1 text-red-500">{{error}}</p>
        </div>

        <div class="mt-5 flex md:justify-end justify-center" style="width: 100%">
          <button class="p-3 rounded-md bg-green-500 text-white z-10" @click="handleConnection()">Connexion</button>
        </div>
      </article>
    </section>
    
    <div class="cgu">
      <router-link style="margin-right: 15px;" to="/cgu">CGU</router-link>
      <router-link to="/privacy-policy">Politique de confidentialité</router-link>
    </div>
    <img class="vague" src="../assets/wave.svg" />
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  data() {
    return {
      form: {
        pseudo: '',
      },
      error: ""
    };
  },
  methods: {
    ...mapActions(['setSocket']),
    handleConnection() {
      if (this.form.pseudo.length > 30) {
        this.error = "C'est trop long connard ! (30 caractères max)"
        return
      }

      if (this.form.pseudo) {
        try {
          this.setSocket(this.form.pseudo)
          this.$router.push('/lobby');
        } catch (error) {
          this.error = error
        }
      } else {
        this.error = "Tu pense pas que le pseudo est obligatoire ?! Boloss va"
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.main-container {
  position: relative;
  overflow: hidden;
  height: 100vh;

  .second-container {
    height: 70vh;

    @media screen and (max-width: 600px) {
      height: 100vh;
    }
  }

  article {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media screen and (max-width: 1200px) {
      width: 80%;
    }

    input {
      width: 100%;
    }

    .error {
      border: 2px solid rgb(239, 68, 68)!important;
    }
  }

  .logo {
    width: 100%!important;
    max-width: 400px!important;
    margin-top: 50px;
    @media screen and (max-width: 1400px) {
      margin-top: 0;
    }
  }

  #pseudo {
    width: 100%;
  }

  .vague {
    position: absolute;
    bottom: 0;
    max-width: unset!important;
    z-index: -1;

    @media screen and (max-width: 600px) {
      width: 180vw;
    }

    @media screen and (max-width: 770px) and (max-height: 540px) {
      display: none;
    }
  }

  .cgu {
    position: absolute;
    bottom : 15px;
    display: flex;
    justify-content: center;
    width: 100%;
    z-index: 12;
    cursor: pointer;
  }
}
</style>
